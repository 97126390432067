
import { defineComponent, onMounted, nextTick, ref } from "vue";
import { Comparator } from "prettier";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import variables from "@/router/api";
import { showModal } from "@/core/helpers/dom";
import ResusableFunctions from "@/composables/ReusableFunctions";
import ClaimSettlementOfferDecline from "@/components/modals/ClaimSettlementOfferDecline.vue";

export default defineComponent({
  name: "settlement-offers-table-component",
  components: { ClaimSettlementOfferDecline },
  props: {
    redirect: String,
    policySummary: Object,
  },
  data() {
    return {
      offers: [{} as any],
      folioItem: "",
    };
  },
  setup() {
    const {
      reinitialization,
      useReusableNavigation,
      displayDatatable,
      getSettlementOfferStatus,
      isEmptyArray,
    } = ResusableFunctions();

    const receipts = ref([{} as any]);

    const isSettlementModalVisible = ref(false);

    const details = ref({
      publicId: "",
      claimReference: "",
    });

    // Function to get settlement receipts
    const getSettlementReceipts = async () => {
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get(`${variables.BROKER_ROUTE}/payment-evidence`)
          .then(({ data }) => {
            //Assign data to form fields
            receipts.value = data.data;
          })
          .catch(function(error) {
            variables.toastAlert(error.response.data.error, "error");
          });
      }
    };

    const addCommaToNumberString = (amount) => {
      return variables.addCommaToNumberString(amount);
    };

    const displayDeclineModal = (publicId: string, claimReference: string) => {
      details.value = {
        publicId,
        claimReference,
      };

      setTimeout(() => {
        nextTick(() => {
          const modal = document.getElementById(
            "claim_settlement_offer_decline_modal"
          );
          if (modal) {
            showModal(modal);
          }
        });
      }, 100);

      isSettlementModalVisible.value = true;
    };

    // Call the getSettlementReceipts function when the component is mounted
    onMounted(() => {
      getSettlementReceipts();

      if (!isEmptyArray(receipts.value)) {
        displayDatatable(["basicExample"]);
      }
    });

    return {
      getSettlementReceipts,
      displayDatatable,
      useReusableNavigation,
      reinitialization,
      displayDeclineModal,
      getSettlementOfferStatus,
      addCommaToNumberString,
      isSettlementModalVisible,
      details,
      receipts,
    };
  },

  methods: {
    approveSettlementOffer(id, publicId, type) {
      const message = type === "accept" ? "accepted" : "declined";

      setTimeout(() => {
        // Send personal information update request
        if (JwtService.getToken()) {
          ApiService.setHeader();
          ApiService.post(
            `${variables.SETTLEMENT_ROUTE}/${publicId}/payment-evidence/${type}`
          )
            .then(() => {
              // variables.toastAlert(response.data.data.message, "success");
              variables.toastAlert(
                `Settlement receipt was ${message} successfully.`,
                "success"
              );

              this.getSettlementReceipts();
            })
            .catch(function(error) {
              variables.toastAlert(error.response.data.error, "error");
            });
        }
      }, 2000);
    },
    previewSettlementOfferDocument(id, publicId, uniqueFileName) {
      // const submitButton1 = document.getElementById(id);

      // if (submitButton1) {
      // Activate indicator
      // submitButton1.setAttribute("data-kt-indicator", "on");

      // Delete cart item
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.setResponseType("blob");
        ApiService.get(
          `${variables.CLAIMS_ROUTE}/${publicId}/document/${uniqueFileName}`
        )
          .then((response) => {
            // submitButton1.removeAttribute("data-kt-indicator");
            const contentType = response.headers["content-type"];
            const fileURL = window.URL.createObjectURL(
              new Blob([response.data], { type: contentType })
            );

            // Open the file in a new tab (handles both images and documents)
            window.open(fileURL, "_blank");
          })
          .catch(function(error) {
            // submitButton1.removeAttribute("data-kt-indicator");
            console.log(error);
            variables.toastAlert(error.response.data.error, "error");
          });
      }
      // }
    },
  },
});
