
import { defineComponent, onMounted } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import SettlementReceipts from "@/components/reusable/claims/SettlementReceipts.vue";

export default defineComponent({
  name: "broker-settlement-receipt",
  components: { SettlementReceipts },
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("Settlement Receipts", ["Broker", "Claims"]);
    });
  },
  created() {
    //Set page title
    document.title = "Broker Settlement Receipt | " + process.env.VUE_APP_TITLE;
  },
});
